import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import WebForm from "../../components/WebForm";

const WebFormPage = () => {
  return (
    <CommonLayout>
      <WebForm />
    </CommonLayout>
  );
};

export default WebFormPage;
