import React, { useState, useEffect } from "react";
import Style from "./Faq.module.scss";
import FaqTabs from "./FaqTabs";
import FaqResult from "./FaqResult";
import { useFaq } from "../../logic/useFaq";
import first from "lodash/first";
import { Link } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useDimensions } from "../../logic/Dimensions";

const Faq = () => {
  var faqStore = [];

  const [filteredIndex, setFilteredIndex] = useState(0);
  const [dropdownState, setDropdownState] = useState(null);
  const { width } = useDimensions();

  const { faqData } = useFaq();

  useEffect(() => {
    if (faqData?.details?.[0]?.cat_name && width < 768 ) {
      setDropdownState(faqData?.details?.[0]);
          }
  }, [faqData,width]);

  useEffect(() => {
    if (faqData?.details.length > 0) {
      const firstData = first(faqData?.details);
      setFilteredIndex(firstData);
    }
  }, [faqData]);


  return (
    <div className={Style.faqpage}>
      <div className="container">
        <div className={`${Style.smcontainer}`}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className={"breadcrumb-item active"}>
                <span>Frequently Asked Questions</span>
              </li>
            </ol>
          </nav>
        </div>

        <div className={Style.titlehead}>
          <h2>Frequently Asked Questions</h2>
        </div>

        <div
          className={
            faqData
              ? Style.wrapper
              : `${Style.wrapper} ${Style.wrapper_loading}`
          }
        >
          <div className={Style.tabcol}>
            <FaqTabs
              faqData={faqData}
              filteredIndex={filteredIndex}
              setFilteredIndex={setFilteredIndex}
            />
          </div>
          {width < 768 && (
            <DropdownButton
              className={`dropdownbtn ${Style.dropdown}`}
              onSelect={(e) => {
                faqStore?.map((value, i) => {
                  if (value?.id == e) {
                    setDropdownState(value?.data);
                  }
                });
              }}
              title={
                <>
                  <span>{dropdownState?.cat_name}</span>
                </>
              }
            >
              {faqData &&
                faqData?.details?.length > 0 &&
                faqData?.details?.map((value, i) => {
                  faqStore.push({
                    id: value?.cat_id,
                    data: value,
                  });

                  return (
                    <Dropdown.Item eventKey={value?.cat_id} key={i}>
                      {value["cat_name"]}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          )}
          <div className={Style.resultcol}>
            <FaqResult
              faqData={faqData}
              filteredIndex={dropdownState ? dropdownState : filteredIndex}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
