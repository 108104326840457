import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Assets from "../Layouts/Assets";
import Style from "./HomeBanner.module.scss";
import ReactHtmlParser from "react-html-parser";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDimensions } from "../../logic/Dimensions";
import MobileFrameVideo from "./MobileFrameVideo";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const HomeBanner = ({ bannerData }) => {
  const { width } = useDimensions();
  const { status } = useSelector((state) => state.banner);

  const [bnrAnim, setBnrAnim] = useState(false);

  const bannerImage = true;

  useEffect(() => {
    setTimeout(() => {
      setBnrAnim(true);
    }, 120);
  }, [status]);

  const bannercircle = useRef();
  useEffect(() => {
    gsap.to(bannercircle.current, {
      x: 100,
      y: 600,
      duration: 0.5,
      delay: 0.4,
      width: 200,
      height: 350,
      scrollTrigger: {
        trigger: ".home_servicesec",
        scrub: 3,
      },
    });

    const bnrappview = gsap.timeline({
      scrollTrigger: {
        trigger: bannercircle.current,
        scrub: 2,
        start: "top top",
        end: "+=100%",
      },
    });

    bnrappview.to(`.${Style.bnrimagesec}`, {
      scrub: 1,
      y: 50,
    });
  }, [bannerData]);

  return (
    <div
      className={
        bnrAnim
          ? `${Style.homebnrsec} ${bannerImage ? Style.homebnrsec_space : ""} ${
              Style.loaded
            }`
          : `${Style.homebnrsec} ${bannerImage ? Style.homebnrsec_space : ""}`
      }
    >
      <span className={Style.bannercircle} ref={bannercircle}></span>
      <div className="container">
        <div className={Style.bnrow}>
          <div className={Style.bnrtext}>
            <h1>{ReactHtmlParser(bannerData?.details?.["banner-text-"])}</h1>
            {width < 768 ? (
              ""
            ) : (
              <div className={Style.dwldapp}>
                <figure className={Style.qrwrap}>
                  <img
                    src={bannerData?.details?.["banner-qr-code-image"]?.url}
                    alt=""
                  />
                </figure>

                <div className={Style.dwld}>
                  <ul>
                    {bannerData?.details?.["banner-play-store-icon"]?.url && (
                      <li>
                        <Link to="#" target="_blank">
                          <img
                            src={
                              bannerData?.details?.["banner-play-store-icon"]
                                ?.url
                            }
                            alt="play Store"
                          />
                        </Link>
                      </li>
                    )}
                    {bannerData?.details?.["banner-apple-store-icon"]?.url && (
                      <li>
                        <Link to="#" target="_blank">
                          <img
                            src={
                              bannerData?.details?.["banner-apple-store-icon"]
                                ?.url
                            }
                            alt="apple"
                          />
                        </Link>
                      </li>
                    )}
                  </ul>
                  {bannerData?.details?.["banner-qr-code-next-text"] && (
                    <Link to="#">
                      {ReactHtmlParser(
                        bannerData?.details?.["banner-qr-code-next-text"]
                      )}
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>

          {width >= 768 &&
            (bannerData && bannerData?.details?.media_type == "video" ? (
              <div className={Style.bnrimagesec}>
                <figure className={Style.bnrimage}>
                  {bannerData && <img src={Assets.bnrimage} alt="" />}
                  <MobileFrameVideo bannerData={bannerData} />
                </figure>
              </div>
            ) : (
              <div className={`${Style.bnr_image_wrap} ${Style.bnrimagesec}`}>
                <figure className={Style.bnr_image}>
                  <img
                    src={bannerData?.details?.["banner-media-images"]?.url}
                    alt="Home Banner"
                  />
                </figure>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
