import logo from "../../assets/logo.svg";
import logo_white from "../../assets/logo-white.svg";

import page_loader from "../../assets/loader.gif";
/* Home Banner */
import qrcode from "../../assets/home-banner/qrcode.svg";
import playstore from "../../assets/home-banner/playstore.svg";
import appstore from "../../assets/home-banner/appstore.svg";
import bnrimage from "../../assets/home-banner/bannerimg.png";
/* Video */
import bnrvideo from "../../assets/home-banner/appview-min.mp4";
import banner_img from "../../assets/home-banner/banner_img-min.jpg";

import bannerSliderImg_1 from "../../assets/home-banner/home-banner-slider-image-1.png";
import bannerSliderImg_2 from "../../assets/home-banner/home-banner-slider-image-2.png";
import bannerSliderVideo from "../../assets/home-banner/home-banner-slider-video.mp4";
import bannerContentImage from '../../assets/home-banner/banner-content-image.png';


/* Home Service Tab */
import hmeservice_img01 from "../../assets/home-service-tab/img_01.png";
import hmeservice_img02 from "../../assets/home-service-tab/img_02.png";
import hmeservice_img03 from "../../assets/home-service-tab/img_03.png";
import hmeservice_img04 from "../../assets/home-service-tab/img_04.png";
import hmeservice_img05 from "../../assets/home-service-tab/img_05.png";
import hmeservice_img06 from "../../assets/home-service-tab/img_06.png";
/* Exchange Rates */
import countryflag01 from "../../assets/exchange-rates/flag_aus.jpg";
import countryflag02 from "../../assets/exchange-rates/flag_swiss.jpg";
import countryflag03 from "../../assets/exchange-rates/flag_sri_lanka.jpg";
import countryflag04 from "../../assets/exchange-rates/flag_in.jpg";
import applestore from "../../assets/money-exchange/applestore.png";
import playstore2 from "../../assets/money-exchange/playstore.png";
import applestoremble from "../../assets/money-exchange/appstore-mble.png";
import playstore2mble from "../../assets/money-exchange/playstore-mble.png";

/* Money Exchange */
import exchangeimg01 from "../../assets/money-exchange/img01.png";

/* Home Branch Locator */
import locimg from "../../assets/home-branch-locator/loc-img.png";

/* Home Client Testimonials */
import reviewicon from "../../assets/home-client-testimonial/reviewicon.png";
import avatar_01 from "../../assets/home-client-testimonial/avatar_01.jpg";

/* Home Whats News */
import post_img_01 from "../../assets/home-whats-new/news_img_01.jpg";
import post_img_02 from "../../assets/home-whats-new/news_img_02.jpg";
/* Home Career Join Us Banner */
import joinus_shape from "../../assets/home-join-us/shape_01.svg";

/* About Banner Fold */
import abt_bnr_fold_icon_01 from "../../assets/about/banner-fold/icon_01.png";
import abt_bnr_fold_icon_02 from "../../assets/about/banner-fold/icon_02.png";
import abt_bnr_fold_icon_03 from "../../assets/about/banner-fold/icon_03.png";

/* About Page*/
import abt_fin_img_01 from "../../assets/about-finance/img_01.png";
import abt_logo_icon from "../../assets/about-story/logo_icon.svg";
import abt_vp_img from "../../assets/about-story/person_img_01.jpg";

import partner_img_01 from "../../assets/partners_associates/img_01.png";
import partner_img_02 from "../../assets/partners_associates/img_02.png";
import partner_img_03 from "../../assets/partners_associates/img_03.png";
import partner_img_04 from "../../assets/partners_associates/img_04.png";
import partner_img_05 from "../../assets/partners_associates/img_05.png";

/* About Certifications & Awards */
import global_banking_img_01 from '../../assets/certifications_awards/global_banking_finance/img_01.jpg';
import global_banking_img_02 from '../../assets/certifications_awards/global_banking_finance/img_02.jpg';

/* Services Page */
import retail_banner from "../../assets/services/banner/retail-money-transfer/retail-banner.png";
import value_added_banner from "../../assets/services/banner/value-added/value_added_banner.png";
import usrati_banner from "../../assets/services/banner/usrati/usrati_banner.png";
import buy_back_banner from "../../assets/services/banner/buy-back-guarantee/buy_back_banner.png";
import bcd_banner from "../../assets/services/banner/bcd/bcd_banner.png";
import currency_ex_banner from '../../assets/services/banner/currency-exchange/currency_ex_banner.png';
import corp_money_banner from '../../assets/services/banner/corp-money-transfer/corp_money_banner.png';

/* Services Page >> Value Added Services */
import vas_tabimg_01 from '../../assets/services/value-added-services/img_01.jpg';
import vas_tabimg_02 from '../../assets/services/value-added-services/img_02.jpg';
import vas_tabimg_03 from '../../assets/services/value-added-services/img_03.jpg';
import vas_tabimg_04 from '../../assets/services/value-added-services/img_04.jpg';
import vas_tabimg_05 from '../../assets/services/value-added-services/img_05.jpg';
import vas_tabimg_06 from '../../assets/services/value-added-services/img_06.jpg';
import vas_tabimg_07 from '../../assets/services/value-added-services/img_07.jpg';
import vas_tabimg_08 from '../../assets/services/value-added-services/img_08.jpg';


/* Services Page >> Bank Transfer */
import appview_01 from "../../assets/services/bank-transfer/appview.png";
import appview_02 from "../../assets/services/cash-transfer/appview_01.png";

/* Services Page >> Features Section */
import feature_icon_01 from "../../assets/services/features/icon_01.png";
import feature_icon_02 from "../../assets/services/features/icon_02.png";
import feature_icon_03 from "../../assets/services/features/icon_03.png";
import feature_icon_04 from "../../assets/services/features/icon_04.png";

/* Service page >> Usrati */
import what_if_img_01 from "../../assets/services/usrati-page/img_01.jpg";
import what_if_img_02 from "../../assets/services/usrati-page/img_02.jpg";

/* Service Page >> Buy Back Guaratee */
import buy_back_img_01 from "../../assets/services/buy-back-guarantee-page/img_01.jpg";

/* Enquire*/
import captchaimg from "../../assets/from/captcha.png";
import office_img from "../../assets/enquire/office_img.jpg";

/* Careers*/
import career_bnr_img from "../../assets/career-page/career_bnr_img.png";
import carrer_culture_img from "../../assets/career-page/culture_and_values.jpg";

import career_fut_img_01 from "../../assets/career-future/img_01.jpg";
import career_fut_img_02 from "../../assets/career-future/img_02.jpg";
import career_fut_img_03 from "../../assets/career-future/img_03.jpg";

import drop_file from "../../assets/career-resume/drop_file.png";

/* Thanks Modal */
import thanks_img from "../../assets/thank-you/thanks_img.png";

/* Media  */
import media_img_01 from "../../assets/media-detail/img_01.jpg";

/* Currency Exchange */
import usd from '../../assets/flag-icon/USD.jpg';
import dummy_graph from '../../assets/exchange-sendmoney/dummy_graph.png';
import BHD from '../../assets/flag-icon/BHD.svg';


/* 404 */
import error_img from '../../assets/error/error_img.png';

const Assets = {
  logo,
  logo_white,
  page_loader,
  qrcode,
  playstore,
  appstore,
  bnrimage,
  bnrvideo,
  banner_img,
  hmeservice_img01,
  hmeservice_img02,
  hmeservice_img03,
  hmeservice_img04,
  hmeservice_img05,
  hmeservice_img06,
  countryflag01,
  countryflag02,
  countryflag03,
  countryflag04,
  applestore,
  playstore2,
  applestoremble,
  playstore2mble,
  exchangeimg01,
  locimg,
  reviewicon,
  avatar_01,
  post_img_01,
  post_img_02,
  joinus_shape,
  abt_bnr_fold_icon_01,
  abt_bnr_fold_icon_02,
  abt_bnr_fold_icon_03,
  abt_fin_img_01,
  abt_logo_icon,
  abt_vp_img,
  partner_img_01,
  partner_img_02,
  partner_img_03,
  partner_img_04,
  partner_img_05,
  appview_01,
  appview_02,
  feature_icon_01,
  feature_icon_02,
  feature_icon_03,
  feature_icon_04,
  retail_banner,
  value_added_banner,
  usrati_banner,
  buy_back_banner,
  bcd_banner,
  currency_ex_banner,
  corp_money_banner,
  what_if_img_01,
  what_if_img_02,
  buy_back_img_01,
  captchaimg,
  office_img,
  career_bnr_img,
  carrer_culture_img,
  career_fut_img_01,
  career_fut_img_02,
  career_fut_img_03,
  drop_file,
  thanks_img,
  media_img_01,
  usd,
  BHD,
  dummy_graph,
  vas_tabimg_01,
  vas_tabimg_02,
  vas_tabimg_03,
  vas_tabimg_04,
  vas_tabimg_05,
  vas_tabimg_06,
  vas_tabimg_07,
  vas_tabimg_08,
  global_banking_img_01,
  global_banking_img_02,
  error_img,

  bannerSliderImg_1,
  bannerSliderImg_2,
  bannerSliderVideo,
  bannerContentImage,
};

export default Assets;
