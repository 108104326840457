import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import Style from "./HomeBannerSlider.module.scss";
import { Link } from "react-router-dom";
import Icomoon from "../Layouts/IcoMooon";
import { useDimensions } from "../../logic/Dimensions";
import ReactHtmlParser from "react-html-parser";
import { useCurrencyGraph } from "../../logic/useCurrencyGraph";
import { useCurrencyRates } from "../../logic/useCurrencyRates";
import { useHomeOurService } from "../../logic/useHomeOurService";

const HomeBannerSlider = ({ bannerData }) => {
  const windowDimension = useDimensions();
  const bannerSliderRef = useRef();
  const progressRef = useRef();
  const [totalSlides, setTotalSlides] = useState(1);

  const [loadedData, setLoadedData] = useState(false);

  const { graphData, graphOptions, graphDays } = useCurrencyGraph();
  const { currencyRatesData } = useCurrencyRates();
  const { serviceData } = useHomeOurService();

  return (
    <section
      className={`${Style.home_banner_slider}
      ${(serviceData && bannerData && currencyRatesData && graphData && graphOptions && graphDays) ? Style.banner_visible : ''}`}
    >
      <Swiper
        slidesPerView={1}
        modules={[EffectFade, Autoplay, Pagination]}
        effect="fade"
        speed={400}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: ".custom_fraction",
          type: "fraction",
        }}
        ref={bannerSliderRef}
        onInit={(swiper) => {
          setTotalSlides(swiper.slides.length);
        }}
        onSlideChangeTransitionStart={() => {
          if (progressRef.current.classList.contains("fill-anim")) {
            progressRef.current.classList.remove("fill-anim");
          }
          if (progressRef.current.classList.contains("initial-fill-anim")) {
            progressRef.current.classList.remove("initial-fill-anim");
          }
        }}
        onSlideChangeTransitionEnd={() => {
          progressRef.current.classList.add("fill-anim");
        }}
      >
        {bannerData?.details?.map((data, index) => {
          return (
            <SwiperSlide className={Style.home_banner_slider_slide} key={index}>
              {data?.media?.type === "image" ? (
                <figure className={Style.home_banner_slider_image}>
                  <img src={data?.media?.url} alt="Banner" />
                </figure>
              ) : (
                <div className={Style.home_banner_slider_video}>
                  <video
                    src={data?.media?.url}
                    autoPlay
                    muted
                    loop
                    playsInline
                  ></video>
                </div>
              )}
              <div className={Style.home_banner_slider_contents}>
                <div className={Style.slide_content_wrap}>
                  <div
                    className={Style.banner_overlay}
                    style={{ backgroundColor: data?.media?.type === "video" ? "rgba(0, 0, 0, 0.39)" : '' }}
                  />
                  <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={Style.banner_left}>
                        <h1
                          className={`${Style.home_banner_slider_title} anim-title`}
                        >
                          {ReactHtmlParser(data?.title)}
                        </h1>
                      </div>
                      {windowDimension.width >= 1200 &&
                        (data?.contents.type === "plane-content" ? (
                          <div className={Style.banner_right}>
                            <article className={Style.plane_content}>
                              <h4 className={Style.plane_content_title}>
                                {data?.contents?.title}
                              </h4>
                              <p className={Style.plane_content_description}>
                                {data?.contents?.description}
                              </p>
                              <Link
                                to={`/services${data?.contents?.button?.url}`}
                                className="btn white"
                              >
                                <span>{data?.contents?.button?.text}</span>
                              </Link>
                            </article>
                          </div>
                        ) : data?.contents?.type === "card-content" ? (
                          <div className={Style.banner_right}>
                            <div className={Style.card_content}>
                              <figure className={Style.card_content_image}>
                                <img src={data?.contents?.image} alt="Banner" />
                              </figure>
                              <div className={Style.card_content_contents}>
                                <div className={Style.card_content_wrap}>
                                  <h4 className={Style.card_content_title}>
                                    {data?.contents.title}
                                  </h4>
                                  <Link
                                    to={`/services${data?.contents?.button?.url}`}
                                    className="arw-link"
                                  >
                                    <span>
                                      <Icomoon icon="arrow-right" size={12} />
                                    </span>{" "}
                                    {data?.contents?.button?.text}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          data?.contents.type === "" && ""
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={Style.home_banner_slider_progress_wrap}>
        <div className="container">
          <div className={Style.home_banner_slider_progress}>
            <div className="custom_fraction"></div>
            <div className={Style.progress_bar}>
              <div
                className={`${Style.progress_fill} initial-fill-anim`}
                ref={progressRef}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBannerSlider;
