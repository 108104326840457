import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";

const SocialMedias = () => {
  const { footerData } = useSelector((state) => state.footer);
  return (
    <>
      {footerData && (
        <ul>
          <li>
            <a
              href="https://www.facebook.com/SharafExchange/"
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon icon="facebook" size={18} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/SharafExchange"
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon icon="twitter" size={19.65} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/SharafExchange/"
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon icon="linkedin" size={19.65} />
            </a>
          </li>

          <li>
            <a
              href={`https://www.youtube.com/channel/UC3RrKmP9q5wmgVOs5OCMPXA`}
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon icon="youtube" size={23.95} />
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export default SocialMedias;
