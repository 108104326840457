import React from "react";
import IcoMoon from "../../Layouts/IcoMooon";
import Style from "./AllBranch.module.scss";
import ReactHtmlParser from "react-html-parser";

const AllBranch = ({ value }) => {
  return (
    <div className={Style.locitem}>
      <header className={Style.locname}>
        <span className={Style.icon}>
          <IcoMoon icon="location" size={14} />
        </span>
        {value.title}
      </header>
      <ul>
        <li className={Style.addr}>
          {ReactHtmlParser(value?.["branch-address"])}
        </li>
        <li className={Style.mob}>
          Mob:{" "}
          <a href={`tel:${value["branch-mobile-number"]}`}>
            {value["branch-mobile-number"]}
          </a>
        </li>
        <li className={Style.date_time}>
          {value?.["branch-working-hours-repeater"]?.map((data, i) => {
            return (
              <span key={i}>
                <span>{ReactHtmlParser(data?.["week-details"])} : </span>
                <span>{ReactHtmlParser(data?.["working-hours"])}</span>
              </span>
            );
          })}
        </li>
      </ul>
      <div className={Style.arw_btn}>
        <a
          href={
            value?.["branch-direction-link"]
              ? value?.["branch-direction-link"]
              : `https://www.google.com/maps/search/?api=1&query=${value?.["branch-latitude-and-longitude"]}`
          }
          className="arw-link"
          target="_blank"
          rel="noreferrer" 
        >
          <span>
            <IcoMoon icon="arrow-right" size={16} />
          </span>
          Get Direction
        </a>
      </div>
    </div>
  );
};
export default AllBranch;
