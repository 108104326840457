import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Assets from "../Layouts/Assets";
import Style from "./Error.module.scss";

const WebForm = () => {
  useEffect(() => {
    let script1 = document.createElement("script");
    script1.charset = "utf-8";
    script1.type = "text/javascript";
    script1.src =
      "https://crm.zoho.com/crm/WebFormServeServlet?rid=24584dfc971503a0243b9a47ec5602cbff4d49aae05e065f6ab4487a53d26e7egid0366d2f9bc31a6e9a995812ae789abab4f01858995c17fea22853bdb1248ad43&script=$sYG";
    document.body.appendChild(script1);

    // return () => {
    //   document.body.removeChild(script1);
    // };
  }, []);

  return (
    <div className={Style.errorpagesec}>
      <div className="container">
        <script src="https://crm.zoho.com/crm/WebFormServeServlet?rid=24584dfc971503a0243b9a47ec5602cbff4d49aae05e065f6ab4487a53d26e7egid0366d2f9bc31a6e9a995812ae789abab4f01858995c17fea22853bdb1248ad43&script=$sYG" />
      </div>
    </div>
  );
};

export default WebForm;
