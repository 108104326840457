import React, { useEffect, useState } from "react";
import AllBranches from "../AllBranches";
import Style from "./BranchLocator.module.scss";
import { DropdownButton, Dropdown } from "react-bootstrap";
import IcoMoon from "../Layouts/IcoMooon";
import find from "lodash/find";
import { useDispatch } from "react-redux";
import { getBranches } from "../../store/slices/branchesSlice";
import { useBranchLocator } from "../../logic/useBranchLocator";
import { useNearestBranch } from "../../logic/useNearestBranch";
import { Link } from "react-router-dom";
import ProgressLoader from "../ProgressLoader";

const BranchLocator = () => {
  const [currentLocationLoadMore, setCurrentLocationLoadMore] = useState(false);
  const [locTitle, setLocTitle] = useState("All Locations");
  const [currentLocation, setCurrentLocation] = useState("");

  const dispatch = useDispatch();

  const {
    branchData,
    searchHandleChange,
    searchTerm,
    searchData,
    setSearchData,
  } = useBranchLocator();

  useEffect(() => {
    if (branchData?.details) {
      let filteredItems = branchData?.details.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchData(filteredItems);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (branchData?.details) {
      setSearchData(branchData.details);
    }
  }, [branchData]);

  const { postLocation } = useNearestBranch();

  const locationChange = async (e) => {
    if (e !== "All") {
      const loc_data = find(branchData?.details, {
        term_id: parseInt(e),
      });

      setLocTitle(loc_data.name.toUpperCase());
      await dispatch(getBranches({ cat: loc_data?.term_id }));
    } else {
      setLocTitle("All Location");
      await dispatch(getBranches());
    }
  };

  const getLocation = async (e) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position) {
        const location = `${position.coords.latitude},${position.coords.longitude}`;
        postLocation({ location: location, posts_per_page: 3, page: 1 });
        setCurrentLocationLoadMore(true);
        setCurrentLocation(location);
      }
    });
  };

  const searchInstant = (e) => {
    if (searchData?.length !== 0) {
      locationChange(searchData[0]?.term_id);
    } else {
      dispatch(getBranches());
    }
  };

  return (
    <>
      {branchData?.details ? "" : <ProgressLoader />}

      <div className={Style.branchlocpage}>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className={`breadcrumb-item ${Style.pathName} active`}>
                <span>Our Branches</span>
              </li>
            </ol>
          </nav>
          <div className={Style.titlehead}>
            <div className={Style.titlesec}>
              <h2>Our Branches</h2>
            </div>
            <div className={Style.location}>
              <a className={Style.preciseloc} onClick={getLocation}>
                <span className={Style.icon}>
                  <IcoMoon icon="loc-pointer" size={20} />
                </span>
                Use Current Location
              </a>
              <DropdownButton
                id="department-dropdown"
                className={`dropdownbtn ${Style.dropdown}`}
                title={
                  <>
                    <IcoMoon icon="location" size={15} />
                    <span>{locTitle}</span>
                  </>
                }
                onSelect={(e) => {
                  locationChange(e);
                }}
              >
                <div className={Style.search_dropdown_result_search}>
                  <div className={Style.search_dropdown_result_search_wrap}>
                    <button>
                      <IcoMoon icon={"find"} size={14} />
                    </button>
                    <input
                      type="text"
                      placeholder="search"
                      value={searchTerm}
                      onChange={(e) => {
                        searchHandleChange(e);
                        searchInstant(e);
                      }}
                    />
                  </div>
                </div>
                <div className={Style.search_dropdown_result_search_list}>
                  {searchTerm.length < 1 && (
                    <Dropdown.Item eventKey="All">All Locations</Dropdown.Item>
                  )}
                  {searchData && searchData?.length > 0 ? (
                    searchData?.map((value, i) => {
                      return (
                        <Dropdown.Item eventKey={value?.term_id} key={i}>
                          {value?.name.toUpperCase()}
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <span>No results</span>
                  )}
                </div>
              </DropdownButton>
            </div>
          </div>
          <div className={Style.loc_list}>
            <AllBranches
              currentLocationLoadMore={currentLocationLoadMore}
              currentLocation={currentLocation}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchLocator;
