import React from "react";
import { Link } from "react-router-dom";

import Style from "./RatesDesktopView.module.scss";

const RatesDesktopView = ({ searchData }) => {
  return (
    <div className="container">
      <div className={Style.tablesec}>
        <div className={`d-none d-md-block ${Style.table}`}>
          <div className={Style.tablehead}>
            <div className={Style.index}>#</div>
            <div className={Style.currency}>Currency</div>
            <div className={Style.dd_dt}>DD/TT</div>
            <div className={Style.fc_buy}>FC Buy</div>
            <div className={Style.fc_cell}>FC Sell</div>
          </div>
          <div className={Style.bodynote}>
            <ul className={Style.tablebody}>
              {searchData && searchData?.length > 0 ? (
                searchData?.map((value, i) => {
                  return (
                    <li>
                      <div className={Style.index} key={i}>
                        {i + 1}
                      </div>
                      <div className={Style.currency}>
                        <span className={Style.icon}>
                          <img src={value?.country_flag} alt="" />
                        </span>
                        {value?.["title-en"]}
                      </div>

                      <div className={Style.dd_dt}>
                        {parseFloat(value?.dd_tt).toFixed(2)}
                      </div>
                      <div className={Style.fc_buy}>
                        {parseFloat(value?.fc_buy).toFixed(2)}
                      </div>
                      <div className={Style.fc_cell}>
                        {parseFloat(value?.fc_sell).toFixed(2)}
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className={Style.no_result}>
                  <span>No Results</span>
                </div>
              )}
            </ul>
            <div className={Style.tablenote}>
              <p>
                Rates are indicative and subject to change. Contact our{" "}
                <Link to={"/our-branches"}>Nearest Branch</Link> for the
                latest rates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatesDesktopView;
