import React from "react";
import Style from "../Footer.module.scss";
import SocialMedias from "../../../SocialMedias";
import CompanyMenu from "./CompanyMenu";
import ServicesMenu from "./ServicesMenu";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import FooterMobileMenu from "./FooterMobileMenu";
import { useDimensions } from "../../../../logic/Dimensions";

const FooterMenus = () => {
  const { footerData } = useSelector((state) => state.footer);
  const { width } = useDimensions();

  return (
    <div className={Style.ftr_menu_addr}>
      {width < 768 ? (
        <div className={Style.cols}>
          <div className={Style.mobileview}>
            <FooterMobileMenu />
          </div>
        </div>
      ) : (
        <>
          <div className={`${Style.cols} ${Style.companycol}`}>
            <CompanyMenu />
          </div>
          <div className={`${Style.cols} ${Style.servicecol}`}>
            <ServicesMenu />
          </div>
        </>
      )}

      <div className={`${Style.cols} ${Style.officecol}`}>
        <div className={Style.reachout}>
          {footerData && <div className={Style.titlesec}>Head Office</div>}
          <p>
            {ReactHtmlParser(
              footerData?.details?.footer_more_details?.wac_footer_address
            )}
          </p>
          <SocialMedias footerData={footerData} />
        </div>
      </div>
    </div>
  );
};
export default FooterMenus;
