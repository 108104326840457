import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Style from "./ServiceCashTransfer.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import Assets from "../Layouts/Assets";

import ServiceContent from "../ServiceContentSection/ServiceContent";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const ServiceCashTransfer = () => {
  const cashtrasfershape = useRef();
  useEffect(() => {
    gsap.to(cashtrasfershape.current, {
      x: -150,
      y: 900,
      duration: 1,
      delay: 0.4,
      opacity: 0.2,
      scrollTrigger: {
        trigger: ".serviceprocessec",
        scrub: 2,
      },
    });
  }, []);

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  return (
    <div
      className={
        pageService.loaderClass
          ? `${Style.cashtrasfersec} ${Style.loaded}`
          : Style.cashtrasfersec
      }
    >
      <span className={Style.circleshape} ref={cashtrasfershape}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.imgcol}>
            <div className={Style.devicewrap}>
              <figure className={Style.imgwrap}>
                <div className={Style.imgsec}>
                  <img src={Assets.appview_02} />
                </div>
              </figure>
              <div className={`${Style.dashboard} bounceanim`}>
                <div className={Style.box}>
                  <div className={Style.cnt}>
                    <header>Dashboard</header>
                    <div className={Style.price}>$ 56,89.34</div>
                    <span className={Style.note}>Total Balance</span>
                  </div>
                  <div className={Style.arwup}>
                    <button className={Style.upbtn}>
                      <IcoMoon icon="arrow-down" size={22} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Style.textcol}>
            <ServiceContent
              serviceData={
                pageService?.[slug]?.details[0] &&
                pageService?.[slug]?.details[0]?.page_sections?.[
                  "page-details-section-one"
                ]?.["page-details-section-one-items"]?.[1]
              }
              btnData={pageService?.[slug]?.details[0]?.page_sections}
              loaderClass={pageService.loaderClass}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCashTransfer;
