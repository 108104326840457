import React, { useRef } from "react";

import { useSelector } from "react-redux";

import AboutBanner from "../AboutBanner";
import AboutFinance from "../AboutFinance";
import AboutPartnersAndAssociates from "../AboutPartnersAndAssociates";
import AboutStory from "../AboutStory";
import AboutVisionAndMission from "../AboutVisionAndMission";
import CertificationsTab from "../CertificationsTab";

import { useAboutUs } from "../../logic/useAboutUs";
import ProgressLoader from "../ProgressLoader";
import { TitleComponent } from "../TitleComponent";

const AboutUs = () => {
  const aboutRef = useRef(null);
  const { aboutBannerData } = useAboutUs();
  const { isActive } = useSelector((state) => state.pageAbout);

  return (
    <div>
      {aboutBannerData ? "" : <ProgressLoader />}
      <TitleComponent
        title={aboutBannerData?.details?.wpseo?.title}
        description={aboutBannerData?.details?.wpseo?.metadesc}
      />

      <AboutBanner
        aboutRef={aboutRef}
        aboutBannerData={aboutBannerData}
        isActive={isActive}
      />
      <AboutFinance
        aboutRef={aboutRef}
        aboutBannerData={aboutBannerData}
        isActive={isActive}
      />
      <AboutStory />
      <AboutPartnersAndAssociates />
    </div>
  );
};

export default AboutUs;
