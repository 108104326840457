import React, { useState, useRef, useEffect } from "react";
import Style from "./DesktopNav.module.scss";
import { Link } from "react-router-dom";
import LoginSignup from "../LoginSignup";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Search from "../Search";
import { changeStatus } from "../../../../store/slices/serviceSlice";

const DesktopNav = ({
  setActiveSearch,
  closeSearch,
  openSearch,
  searchTerm,
  searchHandleChange,
  searchData,
  setSearchTerm,
  menuData,
}) => {
  const { isActive } = useSelector((state) => state.menu);
  const pageService = useSelector((state) => state.pageService);
  const dispatch = useDispatch();

  const [dark, setDark] = useState(localStorage.getItem("lang"));
  const serviceRef = useRef(false);

  let location = useLocation();

  useEffect(() => {
    if (localStorage.i18nextLng == "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
  }, [localStorage.i18nextLng]);

  const clickLang = (lang) => {
    if (lang === "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  let navMenu = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Us",
      path: "/about-us",
    },
    {
      name: "Services",
      path: "",
      sub_menu: [
        {
          name: "Currency Exchange",
          path: "/services/currency-exchange",
        },
        {
          name: "Retail Money Transfer",
          path: "/services/retail-money-transfer",
        },
        {
          name: "Corporate Money Transfer",
          path: "/services/corporate-money-transfer",
        },
        {
          name: "Value Added Services",
          path: "/services/value-added-services",
        },
        {
          name: "Usrati",
          path: "/services/usrati-program",
        },
        {
          name: "Buy Back Guarantee",
          path: "/services/buy-back-guarantee",
        },
        {
          name: " BCD",
          path: "/services/bulk-cash-department",
        },
      ],
    },
    {
      name: "Media",
      path: "/media",
    },
    {
      name: "Careers",
      path: "/careers",
    },
    {
      name: "Enquire",
      path: "/enquiry",
    },
    {
      name: "Branches",
      path: "/our-branches",
    },
  ];

  const handleClickSubMenu = (slug) => {
    let newSlug = slug.split("/")[2];
    if (!pageService?.[newSlug]) {
      dispatch(changeStatus(newSlug));
    }
    serviceRef.current = true;
  };

  return (
    <>
      <nav className={Style.mainav}>
        {isActive ? (
          <ul>
            {menuData?.details?.length > 0 &&
              menuData?.details?.map((item, i) => {
                let activeMedia =
                  location.pathname.split("/")[1] == "media-detail" &&
                  item?.url == "/media";

                let activeCarrer =
                  location.pathname.split("/")[1] == "careers-detail" &&
                  item?.url == "/careers";
                let activeMenu = location.pathname == item?.url;
                return (
                  <>
                    {item?.title == "Services" ? (
                      <li
                        className={
                          location.pathname.includes("services")
                            ? Style.active
                            : ""
                        }
                        key={i}
                      >
                        <Link to={`${item?.url}`}>{item?.title}</Link>
                        <ul>
                          {item?.sub_menu.map((menuItem, i) => {
                            let activeSubMenu =
                              location.pathname == menuItem?.url;
                            return (
                              <li
                                className={activeSubMenu ? Style.active : ""}
                                onClick={() =>
                                  handleClickSubMenu(menuItem?.url)
                                }
                                key={i}
                              >
                                <Link to={`${menuItem?.url}`}>
                                  {menuItem?.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ) : (
                      <li
                        className={
                          activeMenu
                            ? Style.active
                            : activeMedia
                            ? Style.active
                            : activeCarrer
                            ? Style.active
                            : ""
                        }
                      >
                        <Link to={`${item?.url}`}>{item.title}</Link>
                      </li>
                    )}
                  </>
                );
              })}
          </ul>
        ) : (
          <ul className={Style.static_menu}>
            {navMenu.map((item, i) => {
              let activeMenu = location.pathname == item?.path;
              return (
                <>
                  {item?.name == "Services" ? (
                    <li
                      className={
                        location.pathname.includes("services")
                          ? Style.active
                          : ""
                      }
                      key={i}
                    >
                      <Link to={`${item?.path}`}>{item?.name}</Link>
                      <ul>
                        {item?.sub_menu.map((menuItem, i) => {
                          let activeSubMenu =
                            location.pathname == menuItem?.path;
                          return (
                            <li
                              className={activeSubMenu ? Style.active : ""}
                              onClick={() => handleClickSubMenu()}
                              key={i}
                            >
                              <Link to={`${menuItem?.path}`}>
                                {menuItem?.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ) : (
                    <li className={activeMenu ? Style.active : ""}>
                      <Link to={`${item?.path}`}>{item.name}</Link>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        )}
        <Search
          closeSearch={closeSearch}
          openSearch={openSearch}
          searchTerm={searchTerm}
          searchHandleChange={searchHandleChange}
          searchData={searchData}
          setSearchTerm={setSearchTerm}
          setActiveSearch={setActiveSearch}
        />
      </nav>
      <LoginSignup />
    </>
  );
};

export default DesktopNav;
