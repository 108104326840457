import React, { useEffect, useState } from "react";
import RatesDesktopView from "./RatesDesktopView";
import RatesMobileView from "./RatesMobileView";
import Style from "./ServiceExchangeRates.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { useCurrencyTableRates } from "../../logic/useCurrencyTableRates";
import { useLocation } from "react-router-dom";

const ServiceExchangeRates = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);

  const location = useLocation();

  const { currencyTableRatesData } = useCurrencyTableRates();

  const searchHandleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    if (currencyTableRatesData && currencyTableRatesData?.details?.length > 0) {
      setSearchData(currencyTableRatesData?.details);
    }
  }, [currencyTableRatesData]);

  useEffect(() => {
    if (currencyTableRatesData?.details) {
      let filteredItems = currencyTableRatesData?.details.filter((item) => {
        return item?.["title-en"]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchData(filteredItems);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (location?.state?.openPosition) {
      clickOpenPosition();
    }
  }, []);

  const clickOpenPosition = () => {
    let elem = document.querySelector(`.todayRate`);

    setTimeout(() => {
      window.scrollTo({
        top: elem.getBoundingClientRect().top - 150,
        left: 0,
        behavior: "smooth",
      });
    }, 10);
  };

  return (
    <div className={Style.todaysratesec}>
      <div className="container">
        <div className={Style.title_search}>
          <div className={`${Style.titlesec} todayRate`}>
            <h2>Today’s exchange rates</h2>
          </div>
          <div className={`d-none d-md-block ${Style.search}`}>
            <div className={Style.searcholder}>
              <span className={Style.searchicon}>
                <IcoMoon icon="find" size={16.6} />
              </span>
              <input
                value={searchTerm}
                className={Style.searchinput}
                type="text"
                id="search_currency"
                placeholder={"Search Currency"}
                onChange={(e) => {
                  searchHandleChange(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <RatesDesktopView searchData={searchData} />
      <RatesMobileView searchData={searchData} />
    </div>
  );
};

export default ServiceExchangeRates;
