import React, { useEffect } from "react";
import Style from "./Branches.module.scss";
import SingleBranch from "./SingleBranch";
import { useBranches } from "../../logic/useBranches";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Branches = () => {
  const { branchesData, fetchBranchDetails } = useBranches();
  useEffect(() => {
    fetchBranchDetails({
      posts_per_page: 3,
      page: 1,
    });
  }, []);

  return (
    <div className={`${Style.branchloc} `}>
      {branchesData?.details &&
        branchesData?.details.length > 0 &&
        branchesData?.details.map((value, i) => {
          return (
            <div key={i} className={"branch-el"}>
              <SingleBranch value={value} />
            </div>
          );
        })}
    </div>
  );
};

export default Branches;
