import React, { useRef, useState, useEffect } from "react";
import Style from "./ServiceProcess.module.scss";
import SwiperCore, { FreeMode, Navigation, Thumbs, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const ServiceProcess = () => {
  const [showActive, setShowActive] = useState(0);
  SwiperCore.use([FreeMode, Navigation, Thumbs, EffectFade]);

  const triggerThis = useRef();
  useEffect(() => {
    gsap.to(triggerThis.current, {
      y: 100,
      rotate: 20,
      width: 600,
      height: 600,
      opacity: 0.2,
      duration: 6,
      delay: 4,
      scrollTrigger: {
        trigger: triggerThis.current,
        scrub: 2,
      },
    });
    gsap.to(triggerThis.current, {
      x: -200,
      width: 400,
      height: 400,
      opacity: 0.2,
      duration: 2,
      delay: 2,
      filter: "blur(80px)",
      scrollTrigger: {
        trigger: triggerThis.current,
        scrub: 1.5,
      },
    });
  }, []);
  useEffect(() => {
    const timer1 = setTimeout(() => {
      setShowActive(showActive + 1);
    }, 5000);
    if (
      showActive &&
      showActive >=
        pageService?.[slug]?.details[0]?.page_sections?.["service-process"]?.[
          "service-process-items"
        ].length
    ) {
      setShowActive(0);
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [showActive]);

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  return (
    <div
      className={
        pageService.loaderClass
          ? `serviceprocessec ${Style.serviceprocessec} ${Style.loaded}`
          : `serviceprocessec ${Style.serviceprocessec}`
      }
    >
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.textcol}>
            <div className={Style.textwrap}>
              <span className={Style.circleshape} ref={triggerThis}></span>
              <header className={`smtitle ${Style.smtitle}`}>
                {
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "service-process"
                  ]?.["service-process-first-small-title"]
                }
              </header>
              <h2>
                {
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "service-process"
                  ]?.["service-process-main-title"]
                }
              </h2>
              <p>
                {
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "service-process"
                  ]?.["service-process-main-description"]
                }
              </p>
              
            </div>
          </div>
          <div className={Style.tabcol}>
            <div className={Style.tabwrapper}>
              <ul className={Style.steptabs}>
                {pageService?.[slug]?.details[0]?.page_sections?.[
                  "service-process"
                ]?.["service-process-items"] &&
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "service-process"
                  ]?.["service-process-items"].length > 0 &&
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "service-process"
                  ]?.["service-process-items"].map((value, i) => {
                    return (
                      <li
                        className={`${showActive === i && `${Style.active}`}`}
                        key={i}
                        onClick={() => setShowActive(i)}
                      >
                        <div className={Style.tabitem}>
                          <header className={Style.stepcount}>
                            {value?.["process-step-number"]}
                          </header>
                          <header className={Style.itemtitle}>
                            {value?.["process-step-title"]}
                          </header>
                          <div className={Style.itemcnt}>
                            <p>{value?.["process-step-description"]}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceProcess;
