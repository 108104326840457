import React, { useEffect } from "react";
import Style from "./AboutVisionAndMission.module.scss";
import { useAboutUs } from "../../logic/useAboutUs";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutVisionAndMission = () => {
  const { aboutBannerData } = useAboutUs();
  const { isActive } = useSelector((state) => state.pageAbout);

  return (
    <>
      {aboutBannerData && (
        <div
          className={
            isActive
              ? `${Style.vimisionsec} ${Style.loaded}`
              : Style.vimisionsec
          }
        >
          <div className="container">
            <div className={Style.wrapper}>
              {aboutBannerData?.details?.["section-our-mission-and-vision"]?.[
                "section-our-mission-and-vision-items"
              ] &&
                aboutBannerData?.details?.["section-our-mission-and-vision"]?.[
                  "section-our-mission-and-vision-items"
                ].length > 0 &&
                aboutBannerData?.details?.["section-our-mission-and-vision"]?.[
                  "section-our-mission-and-vision-items"
                ].map((value, i) => {
                  return (
                    <div key={i} className={"vision-el"}>
                      <div className={Style.cntsec}>
                        <div className={`h5 ${Style.titlesec}`}>
                          {value?.title}
                        </div>
                        <p>{value?.description}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutVisionAndMission;
