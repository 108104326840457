import React from "react";
import { Link, useLocation } from "react-router-dom";
import Style from "./ServiceContentTwo.module.scss";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const ServiceContentTwo = ({ serviceData, loaderClass }) => {
  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  let data =
    pageService?.[slug]?.details[0]?.page_sections?.[
      "page-details-section-one"
    ]?.["page-details-section-one-items"]?.[1];

  return (
    <>
      <div
        className={
          pageService.loaderClass
            ? `${Style.textwrap} ${Style.loaded}`
            : Style.textwrap
        }
      >
        <header className={`smtitle ${Style.smtitle}`}>
          {data?.["page-details-section-small-title"]}
        </header>
        <h2>{data?.["page-details-section-one-title"]}</h2>
        <ul className="list">
          <p>
            {ReactHtmlParser(data?.["page-details-section-one-description"])}
          </p>
        </ul>
        {pageService.loaderClass &&
          pageService?.[slug]?.details[0]?.page_sections?.[
            "banner-field-set"
          ]?.["banner-field-set-button-url"] && (
            <Link
              to={
                pageService?.[slug]?.details[0]?.page_sections?.[
                  "banner-field-set"
                ]?.["banner-field-set-button-url"]
              }
              className={`btn btn-brdr ${Style.btn}`}
              target={"_blank"}
            >
              <span>
                {
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "banner-field-set"
                  ]?.["banner-field-set-button-text"]
                }
              </span>
            </Link>
          )}
      </div>
    </>
  );
};
export default ServiceContentTwo;
