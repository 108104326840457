import React, { useRef } from "react";
import Style from "./Cookies.module.scss";

const Cookies = ({handleClickAccept}) => {
  return (
    <div>
      <div className={Style.cookies_wrapper}>
        <h2 className={Style.cookies_content}>
          This website uses cookies to enhance the user experience.
        </h2>
        <button className={`btn btn-primary ${Style.cookies_accept_button}`} onClick={() => handleClickAccept()}>
          Accept cookies
        </button>
      </div>
    </div>
  );
};

export default Cookies;
