import React, { useRef, useState } from "react";
import Style from "./CareerForm.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { Dropdown, DropdownButton } from "react-bootstrap";
import * as Yup from "yup";
import { resumeForm } from "../../store/slices/formSlice";
import { useForm } from "../../logic/useForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import GoogleRecaptcha from "../Recaptcha";
import { Spinner } from "../Spinner";

const CareerForm = ({ careersData, careersJobData }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaValid, isCaptchaValid] = useState(false);
  const { formKey } = useForm();
  const [value, setValue] = useState("Area of Interest*");
  const [selectedFile, setSelectedFile] = useState("");

  const [successMessage, setSuccessMessage] = useState(false);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
  const FILE_SIZE = 1000 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "application/pdf",
    "image/png",
  ];

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    mobile_number: Yup.string()
      .matches(phoneRegExp, "*Invalid mobile number")
      .required("Mobile number required")
      .min(8, "Minimum 8 digits is required "),
    interest: Yup.string().required("Area of Interest is required"),
    googleCaptcha: Yup.string().required("reCaptcha is required"),
    files: Yup.mixed()
      .required("Resume is required")
      .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
        return value ? (value.size <= FILE_SIZE ? true : false) : true;
      })
      .test("FILE_Type", "Not valid!", (value) => {
        return value
          ? SUPPORTED_FORMATS.includes(value.type)
            ? true
            : false
          : true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      mobile_number: "",
      interest: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
      googleCaptcha: "",
      files: "",
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        ref.current.value = "";
        setValue("Area of Interest");
        let newData = {
          ...data,
          file_upload: selectedFile,
        };
        resumeSubmit(newData);
        setCaptchaReset(true);
        setSelectedFile("");
        setLoading(true);
      }
    },
  });

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const resumeSubmit = async (data) => {
    var payload = new FormData();
    payload.append("full_name", data.full_name);
    payload.append("email_address", data.email);
    payload.append("mobile_number", data.mobile_number);
    payload.append("upload_your_resume", selectedFile);
    payload.append("area_of_interest", data.interest);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(resumeForm(payload));

    if (resumeForm.fulfilled.match(resultAction)) {
      setSuccessMessage(resultAction?.payload?.data?.success);
      setLoading(false);

      setTimeout(() => {
        setSuccessMessage(false);
      }, 2000);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={Style.fieldwrapper}>
          <div
            className={`input-holder
               ${formik.values.full_name && `active`} ${
              formik.errors.full_name && formik.touched.full_name ? `error` : ""
            }`}
          >
            <input
              placeholder="Full Name*"
              type="text"
              name="full_name"
              id="full_name"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.full_name}
              onBlur={() => {
                formik.handleBlur();
              }}
            />
            <label for="full_name" className="forvalue">
              Full Name*
            </label>

            {formik.touched.full_name && formik.errors.full_name ? (
              <div className="text-danger">{formik.errors.full_name}</div>
            ) : null}
          </div>
          <div>
            <div
              className={`input-holder ${formik.values.email && `active`} ${
                formik.errors.email && formik.touched.email ? `error` : ""
              }`}
            >
              <input
                placeholder="Email Address*"
                type="text"
                name="email"
                id="email"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={() => {
                  formik.handleBlur();
                }}
              />
              <label for="email" className="forvalue">
                Email Address*
              </label>
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder  ${
                formik.values.mobile_number && `active`
              } ${
                formik.errors.mobile_number && formik.touched.mobile_number
                  ? `error`
                  : ""
              }`}
            >
              <input
                placeholder="Mobile Number*"
                type="text"
                name="mobile_number"
                id="mobile_number"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.mobile_number}
                onBlur={() => {
                  formik.handleBlur();
                }}
                maxLength={15}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <label for="mobile_number" className="forvalue">
                Mobile Number*
              </label>
              {formik.touched.mobile_number && formik.errors.mobile_number ? (
                <div className="text-danger">{formik.errors.mobile_number}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder ${formik.values.interest && `active`} ${
                formik.errors.interest && formik.touched.interest ? `error` : ""
              }`}
            >
              <DropdownButton
                className="form-select"
                title={value}
                id="interest"
                name="interest"
                onSelect={(e) => {
                  setValue(e);
                  formik.setFieldValue("interest", e);
                }}
                onChange={formik.handleChange}
                value={formik.values.interest}
                onBlur={() => {
                  formik.handleBlur();
                }}
              >
                {careersJobData?.details?.departments &&
                  careersJobData?.details?.departments.length > 0 &&
                  careersJobData?.details?.departments.map((value, key) => {
                    return (
                      <Dropdown.Item eventKey={value?.name} key={key}>
                        {value?.name}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
              {formik.touched.interest && formik.errors.interest ? (
                <div className="text-danger">{formik.errors.interest}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder ${formik.values.files && `active`} ${
                formik.errors.files && formik.touched.files ? `error` : ""
              }`}
            >
              <div className="uploadwraper">
                <div className="uploadlabel">
                  <label for="file_upload" className="fileupload">
                    <IcoMoon icon="attach" size={15} />
                    <span>
                      {selectedFile?.name
                        ? selectedFile?.name
                        : "Upload Your Resume*"}
                    </span>
                  </label>
                  {formik.touched.files && formik.errors.files ? (
                    <div className="text-danger">{formik.errors.files}</div>
                  ) : null}
                </div>
                <div className="filetypes">
                  {careersData && (
                    <p>File Type: (PDF, DOC, DOCX, JPEG, PNG) Under 7MB.</p>
                  )}
                </div>
              </div>
              <input
                ref={ref}
                type="file"
                name="files"
                id="file_upload"
                accept="application/pdf,image/jpg,
                  image/jpeg,
                  image/png"
                className="file-control"
                onChange={(event) => {
                  handleFileSelect(event);
                  formik.setFieldValue("files", event.currentTarget.files[0]);
                }}
              />
            </div>
          </div>
          <div>
            <div
              className={`input-holder captcha-holder ${
                formik.values.googleCaptcha && `active`
              } ${
                formik.errors.googleCaptcha && formik.touched.googleCaptcha
                  ? `error`
                  : ""
              }`}
            >
              <GoogleRecaptcha
                captchaReset={captchaReset}
                name="googleCaptcha"
                value={formik.values.googleCaptcha}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  changeCaptcha(value);
                  formik.setFieldValue("googleCaptcha", value);
                }}
              />
              {formik.touched.googleCaptcha && formik.errors.googleCaptcha ? (
                <div className="text-danger">{formik.errors.googleCaptcha}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="input-holder">
              {loading ? (
                <button
                  type="submit"
                  className={`btn btn-primary ${Style.submitbtn}`}
                >
                  <Spinner />
                </button>
              ) : (
                <button
                  type="submit"
                  className={`btn btn-primary ${Style.submitbtn}`}
                >
                  <span>Submit</span>
                </button>
              )}
            </div>
          </div>
          {successMessage && (
            <div className={`${Style.success_messsage} success_messsage`}>
              <div>The Form was submitted successfully</div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default CareerForm;
