import React from "react";
import Style from "./HomeBranchLocator.module.scss";
import Assets from "../Layouts/Assets";
import Branches from "../Branches";
import { useNavigate } from "react-router-dom";
import { useHomeBranchLocator } from "../../logic/useHomeBranchLocator";
import { useDimensions } from "../../logic/Dimensions";
import { useBranches } from "../../logic/useBranches";
import { useInView } from "react-intersection-observer";

const HomeBranchLocator = () => {
  const { homeBranchData } = useHomeBranchLocator();

  let navigate = useNavigate();
  const { width } = useDimensions();

  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className={`${Style.branch_loc_sec} ${Style.loaded}`}>
      <div className="container">
        <figure ref={ref1} className={`${Style.locimg} anim`}>
          <img
            src={homeBranchData?.details["home-branch-locator-logo"]?.url}
            alt=""
          />
        </figure>
        <header className={`sectitle ${Style.branch_loc_title}`}>
          <h2 ref={ref2} className={`anim `}>
            {homeBranchData?.details["home-branch-locator-main-title"]}
          </h2>
        </header>
        <div className={Style.shortcnt}>
          <p ref={ref3} className={`anim `}>
            {homeBranchData?.details["home-branch-locator-main-description"]}
          </p>
        </div>

        {width >= 992 && <Branches />}

        <div className={Style.btnwrap}>
          <button
            className={`btn btn-primary ${Style.findbtn}`}
            onClick={() => {
              navigate(`/our-branches`);
            }}
          >
            Find Your Nearest Branch
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeBranchLocator;
