import React, { useRef } from "react";
import { useSelector } from "react-redux";

import CareerBanner from "../CareerBanner";
import CareerCultureAndValues from "../CareerCultureAndValues";
import CareerFuture from "../CareerFuture";
import CareerJobSearch from "../CareerJobSearch";
import CareerResume from "../CareerResume";
import { useCareers } from "../../logic/useCareers";
import { useDimensions } from "../../logic/Dimensions";
import ProgressLoader from "../ProgressLoader";

import { useCareersJobs } from "../../logic/useCareersJobs";

const Careers = () => {
  const result = useCareers();

  const { careersData, isActive } = useSelector((state) => state.careers);
  const { careersJobData } = useCareersJobs();

  const { width } = useDimensions();

  const dropRef = useRef(null);

  return (
    <div>
      {careersData ? "" : careersJobData ? "" : <ProgressLoader />}
      <CareerBanner careersData={careersData} isActive={isActive} />
      <CareerJobSearch careersJobData={careersJobData} />
      <CareerCultureAndValues dropRef={dropRef} />
      {width >= 768 && <CareerFuture />}
      <CareerResume dropRef={dropRef}  careersJobData={careersJobData}/>
    </div>
  );
};

export default Careers;
