import React, { useState } from "react";
import Style from "./LoginSignup.module.scss";
import ModalForm from "../../../ModalForm";
import ModalThanks from "../../../ModalThanks";
import { Modal, Button } from "react-bootstrap";
const LoginSignup = () => {
  const [modalShow, setModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  return (
    <div className={Style.logins}>
      <Button
        onClick={() => {
          setModalShow(true);
          setSuccessMessage(false);
        }}
        className={`btn btn-primary ${Style.loginbtn}`}
      >
        <span>Become a Customer</span>
      </Button>
      <Modal
        size="lg"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        aria-labelledby="applynow-modal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {successMessage ? (
            <ModalThanks setModalShow={setModalShow} />
          ) : (
            <ModalForm
              setModalShow={setModalShow}
              modalShow={modalShow}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginSignup;
