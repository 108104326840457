import Style from "../Faq.module.scss";
import { Accordion } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDimensions } from "../../../logic/Dimensions";
import { useEffect } from "react";

const FaqResult = ({ filteredIndex }) => {
  const { width } = useDimensions();

  const handleSelectAccordion = (e) => {
    const selectedEl = e.target;
    const nonSelectedEl = document.querySelectorAll(".accordion-item");
    nonSelectedEl.forEach((selElement) => {
      selElement.classList.remove("active");
    });
    if (selectedEl.classList.contains("collapsed") == true) {
      selectedEl.parentNode.parentNode.classList.add("active");
    } else {
      selectedEl.parentNode.parentNode.classList.remove("active");
    }
  };

  return (
    <>
      {filteredIndex != null && (
        <Accordion
          defaultActiveKey={0}
          className={`accordionview secondary ${Style.accordions}`}
        >
          {filteredIndex?.faqs &&
            filteredIndex?.faqs.length > 0 &&
            filteredIndex?.faqs.map((value, i) => {
              return (
                <Accordion.Item
                  eventKey={i}
                  key={i}
                  onClick={handleSelectAccordion}
                >
                  <Accordion.Header className={Style.acc_head}>
                    {value?.question}
                  </Accordion.Header>
                  <Accordion.Body className={Style.acc_content}>
                    <p>{ReactHtmlParser(value?.answer)}</p>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

export default FaqResult;
