import React from "react";
import Style from "../Footer.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const FooterBottom = () => {
  let location = useLocation();
  const { footerData } = useSelector((state) => state.footer);

  let termsActive = location.pathname == "/terms-conditions";
  let policyActive = location.pathname == "/privacy-policy";

  return (
    <div className={Style.ftr_btm}>
      <div className={Style.btm_strip}>
        <ul>
          <li>{footerData?.details?.footer_more_details?.copyright}</li>

          {footerData && (
            <li>
              <>
                <Link
                  to="/terms-conditions"
                  className={termsActive && Style.active}
                >
                  Terms and Conditions
                </Link>
              </>
              <>
                <span className={Style.vbar}>|</span>
              </>
              <>
                <Link
                  to="/privacy-policy"
                  className={policyActive && Style.active}
                >
                  Privacy Policy
                </Link>
              </>
            </li>
          )}
          {/* {footerData && (
            <li>
              Designed by:{" "}
              <a href={"https://webandcrafts.com/"} target="_blank" rel="noreferrer" >
                Webandcrafts
              </a>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

export default FooterBottom;
