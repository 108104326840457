import React, { useEffect, useRef } from "react";
import Style from "./EnquireAddress.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const EnquireAddress = ({ enquiryData }) => {
  const { activeClass } = useSelector((state) => state.enquiry);

  const circleone = useRef(),
    circletwo = useRef(),
    ovalshape = useRef(),
    ovaltrigger = useRef(),
    roundrec = useRef();
  useEffect(() => {
    gsap.to(circleone.current, {
      x: -110,
      rotate: 20,
      duration: 1,
      delay: 0.4,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: circleone.current,
        scrub: 1.5,
      },
    });
    gsap.to(circleone.current, {
      y: 200,
      duration: 0.5,
      delay: 0.4,
      width: 500,
      height: 300,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(circletwo.current, {
      x: -300,
      rotate: 20,
      duration: 1,
      delay: 0.5,
      filter: "blur(60px)",
      scrollTrigger: {
        trigger: circletwo.current,
        scrub: 1.5,
      },
    });
    gsap.to(circletwo.current, {
      y: 300,
      duration: 0.5,
      delay: 3,
      opacity: 0,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 1.5,
      },
    });

    gsap.to(ovalshape.current, {
      x: 130,
      y: 500,
      duration: 2,
      delay: 2,
      width: 100,
      scrollTrigger: {
        trigger: ovaltrigger.current,
        scrub: 3,
      },
    });

    gsap.to(roundrec.current, {
      y: 10,
      duration: 0.4,
      delay: 0.5,
      height: "150%",
      opacity: 0.2,
      scrollTrigger: {
        trigger: `${Style.titlesec}`,
        scrub: 2,
      },
    });
  }, []);

  return (
    <div
      className={
        activeClass
          ? `${Style.officeaddrsec} ${Style.loaded}`
          : Style.officeaddrsec
      }
    >
      <span className={Style.circleshape_one} ref={circleone}></span>
      <span className={Style.ovalshape} ref={ovalshape}></span>
      <div className="container">
        <div className={Style.officeaddress}>
          <span className={Style.roundedrecshape} ref={roundrec}></span>
          <span className={Style.circleshape_two} ref={circletwo}></span>
          <div className={`row ${Style.wrapper}`}>
            <div className="col-12 col-lg-5 order-2 order-lg-1">
              <div className={Style.addrbox}>
                <h2>Head Office</h2>
                <div className={Style.addrloc}>
                  <span className={Style.icon}>
                    {activeClass && <IcoMoon icon="location" size={16.5} />}
                  </span>
                  <span>
                    {
                      enquiryData?.details?.["page-more-details"]?.[
                        "head-office-details"
                      ]?.location
                    }
                  </span>
                </div>
                <ul>
                  <li>
                    {ReactHtmlParser(
                      enquiryData?.details?.["page-more-details"]?.[
                        "head-office-details"
                      ]?.["location-address"]
                    )}
                  </li>
                  <li>
                    {" "}
                    Phone:{" "}
                    <a
                      href={`tel:${enquiryData?.details?.["page-more-details"]?.["head-office-details"]?.["phone-number"]}`}
                    >
                      {
                        enquiryData?.details?.["page-more-details"]?.[
                          "head-office-details"
                        ]?.["phone-number"]
                      }
                    </a>
                  </li>
                  <li>
                    {ReactHtmlParser(
                      enquiryData?.details?.["page-more-details"]?.[
                        "head-office-details"
                      ]?.["working-hours"]
                    )}
                  </li>
                </ul>
                <a
                  href={
                    enquiryData?.details?.["page-more-details"]?.[
                      "head-office-details"
                    ]?.["direction-url"]
                  }
                  className="arw-link"
                  target="_blank"
                >
                  <span>
                    <IcoMoon icon="arrow-right" size={16} />
                  </span>
                  Get Direction
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2">
              <div className={Style.figwrap}>
                <figure className={Style.imgwrap}>
                  <img
                    src={
                      enquiryData?.details?.["page-more-details"]?.[
                        "head-office-details"
                      ]?.["head-office-details-image"]?.url
                    }
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquireAddress;
