import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Assets from "../Layouts/Assets";
import Style from "./ServiceBankTransfer.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const ServiceBankTransfer = () => {
  let navigate = useNavigate();

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];
  const clickOpenPosition = () => {
    navigate("/services/currency-exchange", {
      state: { openPosition: true },
    });
  };

  return (
    <div
      className={
        pageService?.loaderClass
          ? `${Style.banktrasfersec} ${Style.loaded}`
          : Style.banktrasfersec
      }
    >
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.textcol}>
            <div className={Style.textwrap}>
              <header className={`smtitle ${Style.smtitle}`}>
                {
                  pageService?.[slug]?.details[0]?.page_sections?.[
                    "page-details-section-one"
                  ]?.["page-details-section-one-items"]?.[0]?.[
                    "page-details-section-small-title"
                  ]
                }
              </header>
              <h2>
                {
                  pageService?.[slug]?.details[0]?.["page_sections"]?.[
                    "page-details-section-one"
                  ]?.["page-details-section-one-items"]?.[0]?.[
                    "page-details-section-one-title"
                  ]
                }
              </h2>
              <p>
                {ReactHtmlParser(
                  pageService?.[slug]?.details[0]?.["page_sections"]?.[
                    "page-details-section-one"
                  ]?.["page-details-section-one-items"]?.[0]?.[
                    "page-details-section-one-description"
                  ]
                )}
              </p>

              {pageService?.loaderClass &&
                pageService?.[slug]?.details[0]?.["page_sections"]?.[
                  "page-details-section-one"
                ]?.["page-details-section-one-items"]?.[0][
                  "page-details-section-one-button-url"
                ] && (
                  <a
                  href=""
                  onClick={(e) => {
                    clickOpenPosition();
                    e.preventDefault();
                  }}
                    className={`btn btn-brdr ${Style.btn}`}
                  >
                    <span>
                      {
                        pageService?.[slug]?.details[0]?.["page_sections"]?.[
                          "page-details-section-one"
                        ]?.["page-details-section-one-items"]?.[0][
                          "page-details-section-one-button-text"
                        ]
                      }
                    </span>
                  </a>
                )}
            </div>
          </div>
          <div className={Style.imgcol}>
            <div className={Style.devicewrap}>
              <figure className={Style.imgwrap}>
                <div className={Style.imgsec}>
                  <img src={Assets.appview_01} />
                </div>
              </figure>
              <div className={`${Style.trasferoute} bounceanim`}>
                <div className={Style.box}>
                  <header>Your transfer route</header>
                  <div className={Style.fromto}>
                    <div className={Style.from}>
                      <span className={Style.icon}>
                        <IcoMoon icon="AE" size={22} />
                      </span>
                      <span>AED</span>
                    </div>
                    <span className={Style.toarw}>
                      <IcoMoon icon="arrow-right" size={22} />
                    </span>
                    <div className={Style.to}>
                      <span className={Style.icon}>
                        <IcoMoon icon="IN" size={22} />
                      </span>
                      <span>INR</span>
                    </div>
                  </div>
                  <div className={Style.timelaps}>
                    <header>Should arrive</header>
                    <div className={Style.hours}>Within 24 to 72 hours</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBankTransfer;
