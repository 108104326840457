import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import CurrencyFold from "../CurrencyFold";
import ServiceExchangeRates from "../ServiceExchangeRates";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import ExchangeSendMoney from "../ExchangeSendMoney";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { TitleComponent } from "../TitleComponent";
import { changeStatus } from "../../store/slices/serviceSlice";

const ServiceCurrencyExchange = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);

  const dispatch = useDispatch();

  const pageService = useSelector((state) => state.pageService);

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, []);

  return (
    <>
      <TitleComponent
        title={pageService?.[loc]?.details[0]?.wpseo_title}
        description={pageService?.[loc]?.details[0]?.wpseo_metadesc}
      />
      <ServiceBanner />
      <CurrencyFold />
      <ServiceExchangeRates />
      <ExchangeSendMoney />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default ServiceCurrencyExchange;
