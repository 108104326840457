import React, { useEffect, useRef, useState } from "react";
import Style from "./ServiceFaq.module.scss";
import Accordion from "react-bootstrap/Accordion";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const ServiceFaq = () => {
  const faqcircleshape = useRef();
  useEffect(() => {
    gsap.to(faqcircleshape.current, {
      x: 50,
      y: 400,
      duration: 1,
      delay: 0.4,
      scrollTrigger: {
        trigger: faqcircleshape.current,
        scrub: 2,
      },
    });
  }, []);

  const handleSelectAccordion = (e) => {
    const selectedEl = e.target;
    const nonSelectedEl = document.querySelectorAll(".accordion-item");
    nonSelectedEl.forEach((selElement) => {
      selElement.classList.remove("active");
    });
    if (selectedEl.classList.contains("collapsed") == true) {
      selectedEl.parentNode.parentNode.classList.add("active");
    } else {
      selectedEl.parentNode.parentNode.classList.remove("active");
    }
  };

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  return (
    <div
      className={
        pageService.loaderClass
          ? `${Style.servicefaqsec} ${Style.loaded}`
          : Style.servicefaqsec
      }
    >
      <span className={Style.circleshape} ref={faqcircleshape}></span>
      <div className="container">
        <div className={Style.headingsec}>
          <h2>
            {
              pageService?.[slug]?.details[0]?.page_sections?.faq?.[
                "faq-main-title"
              ]
            }{" "}
          </h2>
        </div>
        <div className={Style.wrapper}>
          <Accordion
            defaultActiveKey={0}
            className={`accordionview secondary ${Style.accordions}`}
          >
            {pageService?.[slug]?.details[0]?.page_sections?.faq?.[
              "faq-items"
            ] &&
              pageService?.[slug]?.details[0]?.page_sections?.faq?.["faq-items"]
                .length > 0 &&
              pageService?.[slug]?.details[0]?.page_sections?.faq?.[
                "faq-items"
              ].map((value, i) => {
                return (
                  <Accordion.Item
                    eventKey={i}
                    key={i}
                    onClick={handleSelectAccordion}
                  >
                    <Accordion.Header className={Style.acc_head}>
                      {value?.["faq-question"]}
                    </Accordion.Header>
                    <Accordion.Body className={Style.acc_content}>
                      <p>{value?.["faq-answer"]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>

        
        </div>
      </div>
    </div>
  );
};

export default ServiceFaq;
