import React, { useEffect, useRef, useState } from "react";
import AboutBannerFold from "../AboutBannerFold";
import Style from "./AboutBanner.module.scss";
import IcoMoon from "../Layouts/IcoMooon";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { useDimensions } from "../../logic/Dimensions";
import ReactHtmlParser from "react-html-parser";
import AboutVisionAndMission from "../AboutVisionAndMission";

gsap.registerPlugin(ScrollTrigger);

const AboutBanner = ({ aboutRef, aboutBannerData, isActive }) => {
  
  const circleshape = useRef();
  const { width } = useDimensions();
  useEffect(() => {
    gsap.to(circleshape.current, {
      x: 1000,
      y: 500,
      duration: 0.5,
      delay: 0.4,
      width: 50,
      height: 50,
      opacity: 0.4,
      filter: "blur(80px)",
      scrollTrigger: {
        scrub: 2,
      },
    });
  });
  

  const [ref1, inView1] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <div
        className={
          isActive ? `${Style.aboutbanner} ${Style.loaded}` : Style.aboutbanner
        }
      >
        <span className={Style.circleshape} ref={circleshape}></span>
        <div className="container">
          <div className={Style.bnrtext}>
            <header
              ref={ref1}
              className={`${Style.smtitle} anim `}
            >
              {ReactHtmlParser(
                aboutBannerData?.details?.["banner-field-set"]?.[
                  "banner-field-set-first-small-text"
                ]
              )}
            </header>
            <h1 ref={ref2} className={`anim `}>
              {ReactHtmlParser(
                aboutBannerData?.details?.["banner-field-set"]?.[
                  "banner-field-set-main-text"
                ]
              )}
            </h1>
            <p ref={ref3} className={`anim `}>
              {ReactHtmlParser(
                aboutBannerData?.details?.["banner-field-set"]?.[
                  "banner-field-set-main-description"
                ]
              )}
            </p>
          </div>
          {width >= 768 && aboutBannerData && (
            <button
              className={Style.godwn_btn}
              onClick={() => {
                aboutRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <IcoMoon icon="arrow-down" size={14} />
            </button>
          )}
        </div>
      </div>
      <AboutVisionAndMission />
      <AboutBannerFold aboutBannerData={aboutBannerData} />
    </>
  );
};

export default AboutBanner;
