import React, { useState } from "react";
import Style from "./EnquireForm.module.scss";
import { Dropdown } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReCAPTCHA from "react-google-recaptcha";
import Assets from "../../Layouts/Assets";
import { useFormik } from "formik";
import { useForm } from "../../../logic/useForm";
import { useBranchLocator } from "../../../logic/useBranchLocator";
import { useDispatch } from "react-redux";
import { enquiryForm } from "../../../store/slices/formSlice";
import * as Yup from "yup";
import GoogleRecaptcha from "../../Recaptcha";

import { Spinner } from "../../Spinner";

const EnquireForm = () => {
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [captchaReset, setCaptchaReset] = useState(false);

  const [captchaValid, isCaptchaValid] = useState(false);
  const { formKey } = useForm();
  const [value, setValue] = useState("Subject*");
  const [titleBranch, setTitleBranch] = useState("Branch*");

  const [count, setCount] = useState(0);
  const { branchData } = useBranchLocator();

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    message: Yup.string().required("Message is required"),
    branch: Yup.string().required("Branch is required"),
    subject: Yup.string().required("Subject is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    enq_mobile_number: Yup.string()
      .matches(phoneRegExp, "*Invalid mobile number")
      .required("Mobile number required")
      .min(8, "Minimum 8 digits is required "),
    googleCaptcha: Yup.string().required("reCaptcha is required"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      enq_mobile_number: "",
      subject: "",
      branch: "",
      message: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
      googleCaptcha: "",
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        setValue("Subject");
        setTitleBranch("Branch");
        enquirySubmit(data);
        setCaptchaReset(true);
        setCount(0);
        setLoading(true);
      }
    },
  });
  const dispatch = useDispatch();

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const enquirySubmit = async (data) => {
    var payload = new URLSearchParams();
    payload.append("full_name", data.full_name);
    payload.append("email_address", data.email);
    payload.append("mobile_number", data.enq_mobile_number);
    payload.append("subject", data.subject);
    payload.append("branch", data.branch);
    payload.append("message", data.message);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(enquiryForm(payload));

    if (enquiryForm.fulfilled.match(resultAction)) {
      setMessage(resultAction?.payload?.data?.success);
      setLoading(false);
      setTimeout(() => {
        setMessage(false);
      }, 2500);
    }
  };

  return (
    <div className={Style.enquireformsec}>
      <form onSubmit={formik.handleSubmit}>
        <div className={Style.fieldwrapper}>
          <div>
            <div
              className={`input-holder
               ${formik.values.full_name && `active`} ${
                formik.errors.full_name && formik.touched.full_name
                  ? `error`
                  : ""
              }`}
            >
              <input
                placeholder=" Full Name*"
                type="text"
                name="full_name"
                id="full_name"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.full_name}
                onBlur={() => {
                  formik.handleBlur();
                }}
              />
              <label for="full_name" className="forvalue">
                Full Name*
              </label>

              {formik.touched.full_name && formik.errors.full_name ? (
                <div className="text-danger">{formik.errors.full_name}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder
              ${formik.values.enq_mobile_number && `active`} ${
                formik.errors.enq_mobile_number &&
                formik.touched.enq_mobile_number
                  ? `error`
                  : ""
              }`}
            >
              <input
                placeholder="Mobile Number*"
                type="text"
                name="enq_mobile_number"
                id="enq_mobile_number"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.enq_mobile_number}
                onBlur={() => {
                  formik.handleBlur();
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={15}
              />
              <label for="enq_mobile_number" className="forvalue">
                Mobile Number*
              </label>
              {formik.touched.enq_mobile_number &&
              formik.errors.enq_mobile_number ? (
                <div className="text-danger">
                  {formik.errors.enq_mobile_number}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder ${formik.values.email && `active`} ${
                formik.errors.email && formik.touched.email ? `error` : ""
              }`}
            >
              <input
                placeholder="Email Address*"
                type="text"
                name="email"
                id="email"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={() => {
                  formik.handleBlur();
                }}
              />
              <label for="email" className="forvalue">
                Email Address*
              </label>
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>

          <div>
            <div
              className={`input-holder ${formik.values.branch && `active`} ${
                formik.errors.branch && formik.touched.branch ? `error` : ""
              }`}
            >
              <DropdownButton
                className="form-select"
                title={titleBranch}
                name="branch"
                id="branch"
                onSelect={(e) => {
                  setTitleBranch(e);
                  formik.setFieldValue("branch", e);
                }}
                onChange={formik.handleChange}
                value={formik.values.branch}
                onBlur={() => {
                  formik.handleBlur();
                }}
              >
                {branchData?.details &&
                  branchData?.details.length > 0 &&
                  branchData?.details.map((value, i) => {
                    return (
                      <Dropdown.Item
                        eventKey={value?.name}
                        id={value?.name}
                        key={i}
                      >
                        {value?.name}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
              {formik.touched.branch && formik.errors.branch ? (
                <div className="text-danger">{formik.errors.branch}</div>
              ) : null}
            </div>
          </div>

          <div>
            <div
              className={`input-holder ${formik.values.subject && `active`} ${
                formik.errors.subject && formik.touched.subject ? `error` : ""
              }`}
            >
              <DropdownButton
                className="form-select"
                title={value}
                id="subject"
                name="subject"
                onSelect={(e) => {
                  setValue(e);
                  formik.setFieldValue("subject", e);
                }}
                onChange={formik.handleChange}
                value={formik.values.subject}
                onBlur={() => {
                  formik.handleBlur();
                }}
              >
                <Dropdown.Item eventKey="Complaint" id="Complaint">
                  Complaint
                </Dropdown.Item>
                <Dropdown.Item eventKey="Enquiry" id="Enquiry">
                  Enquiry
                </Dropdown.Item>
                <Dropdown.Item eventKey="Suggestion" id="Suggestion">
                  Suggestion
                </Dropdown.Item>
              </DropdownButton>
              {formik.touched.subject && formik.errors.subject ? (
                <div className="text-danger">{formik.errors.subject}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder ${formik.values.message && `active`} ${
                formik.errors.message && formik.touched.message ? `error` : ""
              }`}
            >
              <textarea
                placeholder="Message"
                name="message"
                id="message"
                className="form-control"
                onChange={(e) => {
                  setCount(e.target.value.length);
                  formik.handleChange(e);
                }}
                value={formik.values.message}
                maxLength={100}
              />

              <label for="message" className="forvalue">
                Message*
              </label>
              <span className="char-count">{count}/100</span>
              {formik.touched.message && formik.errors.message ? (
                <div className="text-danger">{formik.errors.message}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div
              className={`input-holder captcha-holder ${
                formik.values.googleCaptcha && `active`
              } ${
                formik.errors.googleCaptcha && formik.touched.googleCaptcha
                  ? `error`
                  : ""
              }`}
            >
              <GoogleRecaptcha
                captchaReset={captchaReset}
                name="googleCaptcha"
                value={formik.values.googleCaptcha}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  changeCaptcha(value);
                  formik.setFieldValue("googleCaptcha", value);
                }}
              />
              {formik.touched.googleCaptcha && formik.errors.googleCaptcha ? (
                <div className="text-danger">{formik.errors.googleCaptcha}</div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="input-holder">
              {loading ? (
                <button
                  type="submit"
                  className={`btn btn-primary ${Style.submitbtn}`}
                >
                  <Spinner />
                </button>
              ) : (
                <button
                  type="submit"
                  className={`btn btn-primary ${Style.submitbtn}`}
                >
                  <span>Submit</span>
                </button>
              )}
            </div>
          </div>

          {message && (
            <div className={`${Style.success_messsage} success_messsage`}>
              <div>The Form was submitted successfully</div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default EnquireForm;
