import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import CareerJobList from "./CareerJobList";
import CareerJobMobileView from "./CareerJobMobileView";
import Style from "./CareerJobSearch.module.scss";
import { useCareersJobs } from "../../logic/useCareersJobs";
import { useDimensions } from "../../logic/Dimensions";
import Icomoon from "../Layouts/IcoMooon";

const CareerJobSearch = ({careersJobData}) => {
  const { width } = useDimensions();
  const [selectedDep, setSelectedDep] = useState("All Department");
  const [selectedLoc, setSelectedLoc] = useState("All Location");
  const { careersData, isActive } = useSelector((state) => state.careers);


  const jobs = careersJobData?.details?.jobs;

  const filteredJobs =
    selectedDep == "All Department" && selectedLoc == "All Location"
      ? jobs
      : selectedDep == "All Department" && selectedLoc !== "All Location"
      ? jobs?.filter((value) => {
          return value?.location == selectedLoc;
        })
      : selectedDep !== "All Department" && selectedLoc == "All Location"
      ? jobs?.filter((value) => {
          return value?.department == selectedDep;
        })
      : jobs?.filter((value) => {
          return (
            value?.department == selectedDep && value?.location == selectedLoc
          );
        });

  return (
    <div
      className={`${
        isActive
          ? `${Style.jobresultsec} ${Style.loaded}`
          : `${Style.jobresultsec}`
      } jobSearchSec`}
    >
      <div className="container ">
        <div className={Style.titledropdownsec}>
          <h2>
            {
              careersData?.details?.page_sections?.["job-serach-field-set"]?.[
                "job-serach-field-set-main-text"
              ]
            }
          </h2>

          <div className={Style.dropdownwrap}>
            <DropdownButton
              id="department-dropdown"
              className={`${Style.dropdown} `}
              title={selectedDep}
              onSelect={(e) => {
                setSelectedDep(e);
              }}
            >
              <Dropdown.Item eventKey="All Department">
                All Department
              </Dropdown.Item>

              {careersJobData?.details?.departments &&
                careersJobData?.details?.departments.length > 0 &&
                careersJobData?.details?.departments.map((value, key) => {
                  return (
                    <Dropdown.Item eventKey={value?.name} key={key}>
                      {value?.name}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
            <DropdownButton
              id="department-dropdown"
              className={Style.dropdown}
              title={
                <>
                  <Icomoon icon={"location"} size={14.41} />
                  <span>{selectedLoc}</span>
                </>
              }
              onSelect={(e) => {
                setSelectedLoc(e);
              }}
            >
              <Dropdown.Item eventKey="All Location">
                All Location
              </Dropdown.Item>
              {careersJobData?.details?.locations &&
                careersJobData?.details?.locations.length > 0 &&
                careersJobData?.details?.locations.map((value, key) => {
                  return (
                    <Dropdown.Item eventKey={value?.name} key={key}>
                      {value?.name}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </div>
        </div>

        <>
          <CareerJobList
            careersJobData={careersJobData}
            filteredJobs={filteredJobs}
            selectedDep={selectedDep}
            selectedLoc={selectedLoc}
          />
        </>
        {width < 768 && (
          <CareerJobMobileView
            careersJobData={careersJobData}
            filteredJobs={filteredJobs}
            selectedDep={selectedDep}
            selectedLoc={selectedLoc}
          />
        )}
      </div>
    </div>
  );
};

export default CareerJobSearch;
