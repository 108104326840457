import React, { useEffect, useState, useRef } from "react";
import Style from "./AboutFinance.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAboutUs } from "../../logic/useAboutUs";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";

gsap.registerPlugin(ScrollTrigger);

const AboutFinance = ({ aboutRef, aboutBannerData, isActive }) => {
  const abtCircleShape = useRef();


  useEffect(() => {
    gsap.to(abtCircleShape.current, {
      y: 800,
      duration: 2,
      delay: 1,
      filter: "blur(30px)",
      scrollTrigger: {
        scrub: 2,
      },
    });
  });

  const [ref1, inView1] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div
      className={
        isActive
          ? `${Style.aboutfinancesec} ${Style.loaded}`
          : Style.aboutfinancesec
      }
      ref={aboutRef}
    >
      <span className={Style.circleshape} ref={abtCircleShape}></span>
      <div className="container">
        <div className={`row ${Style.wrapper}`}>
          <div className={Style.cols}>
            <div ref={ref1} className={`${Style.figwrap} anim `}>
              <figure className={Style.imgwrap}>
                {aboutBannerData && (
                  <img
                    src={
                      aboutBannerData?.details?.[
                        "section-featured-image-and-description"
                      ]?.["sfid-featured-image-url"]?.url
                    }
                    alt=""
                  />
                )}
              </figure>
            </div>
          </div>
          <div className={Style.cols}>
            <div className={Style.cntwrap}>
              <h2 ref={ref2} className={`anim `}>
                {ReactHtmlParser(
                  aboutBannerData?.details?.[
                  "section-featured-image-and-description"
                  ]?.["sfid-title"]
                )}
              </h2>
              <div ref={ref3} className={` ${Style.shrtcnt} anim `}>
                {ReactHtmlParser(
                  aboutBannerData?.details?.[
                  "section-featured-image-and-description"
                  ]?.["sfid-description"]
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFinance;
