import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import HomeServicesTab from "../HomeServicesTab";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";
import { TitleComponent } from "../TitleComponent";
import { useLocation } from "react-router-dom";
import { changeStatus } from "../../store/slices/serviceSlice";

const ValueAddedServices = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);
  const { width } = useDimensions();

  const { serviceData, loaderClass } = useSelector(
    (state) => state.pageService
  );

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, []);

  return (
    <div>
      <TitleComponent
        title={pageService?.[slug]?.details[0]?.wpseo_title}
        description={pageService?.[slug]?.details[0]?.wpseo_metadesc}
      />
      <ServiceBanner />
      {width >= 768 && <ServiceBannerFold />}
      <HomeServicesTab
        result={result}
        serviceData={
          pageService?.[slug]?.details[0].page_sections?.["services-provide"]?.[
            "banner-featured-points-items"
          ]
        }
        loaderClass={loaderClass}
      />
      <ServiceFeatures />
      <ServiceFaq />
    </div>
  );
};

export default ValueAddedServices;
