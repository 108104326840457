import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Style from "./Menu.module.scss";
import Assets from "../Assets";
import DesktopNav from "./DesktopNav";
import MobileMenu from "./MobileMenu";
import { useSelector } from "react-redux";
import { useSearch } from "../../../logic/useSearch";

import { useDimensions } from "../../../logic/Dimensions";
import Search from "./Search";
import { useHeader } from "../../../logic/useHeader";
import { useHomeBanner } from "../../../logic/useHomeBanner";
import { useCurrencyGraph } from "../../../logic/useCurrencyGraph";
import { useCurrencyRates } from "../../../logic/useCurrencyRates";
import { useHomeOurService } from "../../../logic/useHomeOurService";
import { useHomeMobApp } from "../../../logic/useHomeMobApp";
import { useHomeTestimonial } from "../../../logic/useHomeTestimonial";

const Menu = () => {
  const {
    activeSearch,
    setActiveSearch,
    openSearch,
    closeSearch,
    searchTerm,
    searchHandleChange,
    searchData,
    setSearchTerm,
  } = useSearch();

  const { bannerData } = useHomeBanner();
  const { graphData, graphOptions, graphDays } = useCurrencyGraph();
  const { currencyRatesData } = useCurrencyRates();
  const { serviceData } = useHomeOurService();
  const { loaderClass } = useSelector((state) => state.service);
  const { mobileData } = useHomeMobApp();
  const { testimonialsData } = useHomeTestimonial();

  const { width } = useDimensions();

  const [active, setActive] = useState("");
  const [logoURL, setLogoURL] = useState(Assets.logo);
  const [isSlim, setIsSlim] = useState(false);

  const location = useLocation();
  const windowDimension = useDimensions();

  useEffect(() => {
    location.pathname === "/"
      ? setLogoURL(Assets.logo_white)
      : setLogoURL(Assets.logo);
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setActive(Style.loaded);
    }, 1000);
  }, []);

  const menuSelect = () => {
    document.body.classList.remove("show-menu");
  };
  const { menuData } = useHeader();

  const { isActive } = useSelector((state) => state.menu);

  const site_logo = menuData?.site_logo?.url;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scroll = window.pageYOffset;
      scroll > 50 ? setIsSlim(true) : setIsSlim(false);
    });
  }, []);

  return (
    <div className={`${Style.siteheader} ${Style.loaded} site-header`}>
      <div className={`container ${Style.siteheader_container}`}>
        <div className={`${Style.headerwrap} header-wrap`}>
          <div className={Style.logosec}>
            {isActive ? (
              <Link to="/" onClick={menuSelect}>
                {windowDimension.width >= 1200 &&
                serviceData &&
                bannerData &&
                currencyRatesData &&
                graphData &&
                graphOptions &&
                graphDays &&
                !isSlim ? (
                  <img src={logoURL} alt="" />
                ) : (
                  <img src={Assets.logo} alt="" />
                )}
              </Link>
            ) : (
              <Link to="/" onClick={menuSelect}>
                {windowDimension.width >= 1200 &&
                serviceData &&
                bannerData &&
                currencyRatesData &&
                graphData &&
                graphOptions &&
                graphDays &&
                isSlim ? (
                  <img src={logoURL} alt="" />
                ) : (
                  <img src={Assets.logo} alt="" />
                )}
              </Link>
            )}
          </div>
          <div
            className={
              activeSearch
                ? `${Style.headeright} search_open`
                : Style.headeright
            }
          >
            {width < 1200 && (
              <Search
                closeSearch={closeSearch}
                openSearch={openSearch}
                searchTerm={searchTerm}
                searchHandleChange={searchHandleChange}
                searchData={searchData}
                setSearchTerm={setSearchTerm}
                setActiveSearch={setActiveSearch}
              />
            )}
            <DesktopNav
              activeSearch={activeSearch}
              closeSearch={closeSearch}
              openSearch={openSearch}
              setActiveSearch={setActiveSearch}
              searchTerm={searchTerm}
              searchHandleChange={searchHandleChange}
              searchData={searchData}
              setSearchTerm={setSearchTerm}
              menuData={menuData}
            />
            <MobileMenu menuData={menuData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
