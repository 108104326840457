import React, { useState } from "react";
import Style from "../HomeServicesTab.module.scss";
import { gsap } from "gsap";
import { useLocation, useParams } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const ServiceTabs = ({ setFilteredIndex, filteredIndex, serviceData }) => {
  const [loadedClass, setLoadedClass] = useState(false);
  const filteredHandler = (value) => {
    setFilteredIndex(value);
    setLoadedClass(true);
    setTimeout(() => setLoadedClass(false), 2000);
  };

  const { commonSlug } = useParams();

  return (
    <div className={`${Style.tabwrapper} ${loadedClass ? Style.loaded : ""} `}>
      <ul>
        {serviceData?.length > 0 &&
          serviceData?.map((value, i) => {
            return value.title || value?.["service-name"] ? (
              <li
                className={`${
                  value === filteredIndex ? Style.active : ""
                }  anim-el`}
                onClick={() => filteredHandler(value)}
                key={i}
              >
                {window.location.pathname == "/services/value-added-services" ||
                window.location.pathname == "/services/" + commonSlug
                  ? value?.["service-name"]
                  : value?.["our-services-list"]}
              </li>
            ) : null;
          })}
      </ul>
    </div>
  );
};
export default ServiceTabs;
