import HomeBanner from "../HomeBanner";
import CurrencyFold from "../CurrencyFold";
import HomeServicesTab from "../HomeServicesTab";
import ExchangeRates from "../ExchangeRates";
import MoneyExchange from "../MoneyExchange";
import HomeBranchLocator from "../HomeBranchLocator";
import Testimonial from "../Testimonial";
import WhatsNew from "../WhatsNew";
import HomeAboutUs from "../HomeAboutUs";
import JoinUs from "../JoinUs";
import Loader from "../Loader";
import { useHomeBanner } from "../../logic/useHomeBanner";
import { useCurrencyGraph } from "../../logic/useCurrencyGraph";
import { useCurrencyRates } from "../../logic/useCurrencyRates";
import { useHomeOurService } from "../../logic/useHomeOurService";
import { useSelector } from "react-redux";
import { TitleComponent } from "../TitleComponent";
import { useHomeMobApp } from "../../logic/useHomeMobApp";
import { useHomeTestimonial } from "../../logic/useHomeTestimonial";
import HomeBannerSlider from "../HomeBannerSlider";
import { useEffect, useState } from "react";
import Cookies from "../Cookies";

const Home = () => {
  const { bannerData } = useHomeBanner();
  const { graphData, graphOptions, graphDays } = useCurrencyGraph();
  const { currencyRatesData } = useCurrencyRates();
  const { serviceData } = useHomeOurService();
  const { loaderClass } = useSelector((state) => state.service);
  const { mobileData } = useHomeMobApp();
  const { testimonialsData } = useHomeTestimonial();

  const [isAccept, setIsAccept] = useState(false);

  serviceData &&
  bannerData &&
  currencyRatesData &&
  graphData &&
  graphOptions &&
  graphDays
    ? document.body.classList.remove("loader-visible")
    : document.body.classList.add("loader-visible");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scroll = window.pageYOffset;
      scroll > 50
        ? document.body.classList.add("slim-header")
        : document.body.classList.remove("slim-header");
    });
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiePolicy", true);
    setIsAccept(!isAccept);
  };

  useEffect(() => {
    if (localStorage.getItem("cookiePolicy") == "true") {
      setIsAccept(false);
    } else {
      setIsAccept(true);
    }
  }, []);

  return (
    <main>
      {serviceData &&
      bannerData &&
      currencyRatesData &&
      graphData &&
      graphOptions &&
      graphDays ? (
        <>
          {isAccept && <Cookies handleClickAccept={handleAccept} />}
          <TitleComponent title="Top Currency Exchange Company in Dubai | Sharaf Exchange" />
          <HomeBannerSlider bannerData={bannerData} />
          <CurrencyFold />
          <ExchangeRates currencyRatesData={currencyRatesData} />
          <HomeServicesTab
            serviceData={serviceData?.details?.["our-services-items"]}
            title={serviceData?.details["service-featured-main-title"]}
            loaderClass={loaderClass}
          />
          {mobileData?.details?.status == "false" ? null : (
            <MoneyExchange mobileData={mobileData} />
          )}

          <HomeBranchLocator />
          <HomeAboutUs />
          {testimonialsData?.details?.section_details?.status ==
          "false" ? null : (
            <Testimonial testimonialsData={testimonialsData} />
          )}
          <WhatsNew />
          <JoinUs />
        </>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Home;
