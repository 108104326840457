import React, { useState } from "react";
import Style from "../Faq.module.scss";

const FaqTabs = ({ setFilteredIndex, filteredIndex, faqData }) => {
  const [loadedClass, setLoadedClass] = useState(false);
  const filteredHandler = (index) => {
    setFilteredIndex(index);
    setLoadedClass(true);
    setTimeout(() => setLoadedClass(false), 2000);
  };
  return (
    <div className={`${Style.tabwrapper} ${loadedClass ? Style.loaded : ""}`}>
      <ul>
        {faqData?.details &&
          faqData?.details.length > 0 &&
          faqData?.details.map((value, i) => {
            return (
              <li
                className={value === filteredIndex ? Style.active : ""}
                onClick={() => filteredHandler(value)}
                key={i}
              >
                {value["cat_name"]}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default FaqTabs;
