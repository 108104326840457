import React from "react";
import { Link } from "react-router-dom";
import Style from "./ServiceContent.module.scss";
import ReactHtmlParser from "react-html-parser";

const ServiceContent = ({ serviceData, loaderClass, btnData }) => {
  return (
    <>
      <div
        className={
          loaderClass ? `${Style.textwrap} ${Style.loaded}` : Style.textwrap
        }
      >
        <header className={`smtitle ${Style.smtitle}`}>
          {serviceData?.["page-details-section-small-title"]}
        </header>
        <h2>{serviceData?.["page-details-section-one-title"]}</h2>
        <ul className="list">
          <p>
            {ReactHtmlParser(
              serviceData?.["page-details-section-one-description"]
            )}
          </p>
        </ul>
        {loaderClass && serviceData?.["page-details-section-one-button-url"] && (
          <>
            <Link
              to={serviceData?.["page-details-section-one-button-url"]}
              target={"_blank"}
              className={`btn btn-brdr ${Style.btn}`}
            >
              <span>
                {serviceData?.["page-details-section-one-button-text"]}
              </span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};
export default ServiceContent;
