import { useEffect, useState } from "react";
import {
  getMediaCategory,
  getMediaData,
  handleSelectedMediaCategory,
} from "../store/slices/mediaSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "./Dimensions";

export const useMedia = () => {
  const { width } = useDimensions();

  const [isActive, setIsActive] = useState();
  const dispatch = useDispatch();
  const { mediaCategoryData, status, selectedMedia, mediaData, isActiveClass } =
    useSelector((state) => state.media);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getMediaCategory());
    }
  }, []);

  useEffect(() => {
    if (mediaCategoryData?.details?.[0]?.term_id) {
      setIsActive(mediaCategoryData?.details?.[0]?.term_id);
      if (!mediaData?.[selectedMedia]) {
        dispatch(
          getMediaData({
            catSlug: mediaCategoryData?.details?.[0]?.term_id,
            perPage: width < 768 ? 1 : width >= 768 && width < 1200 ? 2 : 5,
            page: 1,
          })
        );
      }
      dispatch(
        handleSelectedMediaCategory(mediaCategoryData?.details?.[0]?.term_id)
      );
    }
  }, [mediaCategoryData]);

  return {
    mediaCategoryData,
    mediaData,
    selectedMedia,
    isActive,
    setIsActive,
    isActiveClass,
  };
};
