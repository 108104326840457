import React from "react";
import EnquireAddress from "../EnquireAddress";
import EnquireSection from "../EnquireSection";
import EnquireOtherBranches from "../EnquireOtherBranches";
import { useEnquiry } from "../../logic/useEnquiry";
import { useDimensions } from "../../logic/Dimensions";
import ServiceFaq from "../ServiceFaq";

import ProgressLoader from '../ProgressLoader'
const Enquire = () => {
  const { width } = useDimensions();
  const { enquiryData } = useEnquiry();

  return (
    <>
      {enquiryData ? '' : <ProgressLoader />}
      <EnquireSection enquiryData={enquiryData} />
      <EnquireAddress enquiryData={enquiryData} />
      {width >= 992 && (<EnquireOtherBranches />)}
    </>
  );
};

export default Enquire;
