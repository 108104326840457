import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceBankTransfer from "../ServiceBankTransfer";
import ServiceCashTransfer from "../ServiceCashTransfer";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import ServiceProcess from "../ServiceProcess";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";

import { useLocation } from "react-router-dom";
import { TitleComponent } from "../TitleComponent";
import { changeStatus } from "../../store/slices/serviceSlice";

const RetailMoneyTransfer = () => {
  const location = useLocation();

  let loc = location.pathname.split("/")[2];
  const { width } = useDimensions();
  const result = useService(loc);

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, []);

  return (
    <>
      <TitleComponent
        title={pageService?.[slug]?.details[0]?.wpseo_title}
        description={pageService?.[slug]?.details[0]?.wpseo_metadesc}
      />
      <ServiceBanner />
      {width >= 768 && <ServiceBannerFold />}
      <ServiceBankTransfer
        data={
          pageService?.[slug]?.details[0]?.page_sections?.[
            "page-details-section-one"
          ]?.["page-details-section-one-items"]?.[0]
        }
      />
      <ServiceCashTransfer
        data={
          pageService?.[slug]?.details[0]?.page_sections?.[
            "page-details-section-one"
          ]?.["page-details-section-one-items"]?.[1]
        }
      />
      <ServiceProcess />
      <ServiceFeatures />
      <ServiceFaq />
    </>
  );
};

export default RetailMoneyTransfer;
